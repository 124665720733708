import React from "react";
import "./Load.css";
import { useUserActivation } from "../UserActivationProvider/UserActivationProvider";

function Load() {
    const { setIsActivated } = useUserActivation();

    return (
        <div>
            <div className="loadContainer">
                {/* <div className="logoContainer">DANIEL LANER</div> */}
                <div className="textContainer">
                    <div className="row">daniellaner.com</div>
                </div>
                {/* <div className="textContainer" style={{ width: "100%" }}>
                    <div className="row">
                        {" "}
                        <span>recording</span>
                        <span>✶</span>
                        <span>mixing</span>
                        <span>✶</span>
                        <span>mastering</span>
                    </div>
                </div> */}

                <div className="textContainer">
                    <div className="row">
                        [♫] This site makes use of audio playback
                    </div>
                </div>

                <div className="buttonContainer">
                    <button
                        className="okButton"
                        onClick={() => {
                            document.querySelector(".loadContainer").style.display = "none";
                            setIsActivated(true);
                        }}
                    >
                        Ok{" "}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Load;
