import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card.js";
import { UserActivationProvider } from "../components/UserActivationProvider/UserActivationProvider";
import Footer from "../components/Footer/Footer.js";
import Load from "../components/Load/Load.js";

import works from "../works.json";
import "../index.css";
import AnimatedCard from "../components/AnimatedCard/AnimatedCard.js";

function Home() {
    function setViewportProperty() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run on load and resize
    setViewportProperty(); // Call the function on initial load
    window.addEventListener("DOMContentLoaded", setViewportProperty);
    window.addEventListener("resize", setViewportProperty);

    const [hoveredCardId, setHoveredCardId] = useState(null);
    const [playing, setPlaying] = useState(null);

    function handleCardHover(id) {
        setHoveredCardId(id);
        setPlaying(id ? id : null);
    }

    const duplicatedWorks = works.map((work) => ({
        ...work,
        id: works.length + work.id,
    }));
    const updatedWorks = [...works, ...duplicatedWorks];

    const cards = [];
    for (let i = 0; i < updatedWorks.length; i++) {
        const work = updatedWorks[i];
        cards.push(
            <Card
                imgUrl={work.imgUrl}
                audioUrl={work.audioUrl}
                title={work.title}
                artist={work.artist}
                credits={work.credits}
                link={work.link}
                isPlaying={playing === work.id}
                id={work.id}
                key={work.id}
                onMouseEnter={() => handleCardHover(work.id)}
                onMouseLeave={() => handleCardHover(null)}
            />
        );
    }
    useEffect(() => {
        if (hoveredCardId) {
            const tooltipContainer =
                document.querySelector(".tooltipContainer");
            tooltipContainer.style.opacity = 1;
            document.getElementById("tooltip-title").innerText =
                updatedWorks.find((work) => work.id === hoveredCardId).title;
            document.getElementById("tooltip-artist").innerText =
                updatedWorks.find((work) => work.id === hoveredCardId).artist;
            document.getElementById("tooltip-year").innerText =
                updatedWorks.find((work) => work.id === hoveredCardId).year;
            document.getElementById("tooltip-credits").innerText =
                updatedWorks.find((work) => work.id === hoveredCardId).credits;
        } else {
            document.querySelector(".tooltipContainer").style.opacity = 0;
        }
    }, [hoveredCardId]);

    return (
        <UserActivationProvider>
            <AnimatedCard>
                <div className="pageContainer">
                    <Load />

                    <div className="galleryContainer">
                        <div className="slideTrack"> {cards}</div>

                        <div className="tooltipContainer">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <span id="tooltip-title"></span>
                                <span id="tooltip-year"></span>
                            </div>
                            <span id="tooltip-artist"></span>
                            <span id="tooltip-credits"></span>
                        </div>
                    </div>
                    <Footer />
                </div>
            </AnimatedCard>
        </UserActivationProvider>
    );
}

export default Home;
