import React, { useEffect, useRef } from "react";
import "./Card.css";
import { useUserActivation } from "../UserActivationProvider/UserActivationProvider";

function Card({
    imgUrl,
    audioUrl,
    title,
    artist,
    credits,
    link,
    year,
    id,
    onMouseEnter,
    onMouseLeave,
    isPlaying,
}) {
    const { isActivated } = useUserActivation();
    const audioRef = useRef(null);

    let isPlayingRequested = false;

    async function playAudio(audioElement) {
        try {
            isPlayingRequested = true;
            await audioElement.play();
            isPlayingRequested = false;
        } catch (error) {
            // console.error("Error playing audio:", error);
            // Reset flag if play fails
            isPlayingRequested = false;
        }
    }

    useEffect(() => {
        const initializeAudio = () => {
            audioRef.current = new Audio(audioUrl);
        };

        initializeAudio();

        if (isPlaying && audioRef.current.paused) {
            // let volume = 0;

            // audioRef.current.volume = volume;
            if (isActivated) {
                playAudio(audioRef.current);
            }
            // setInterval(() => {
            //     if (audioRef.current.volume < 0.9) {
            //         audioRef.current.volume += 0.1;
            //     }
            // }, 1000);
        } else if (!isPlaying && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Rewind to start when mouse leaves
        }

        // Cleanup on component unmount
        return () => {
            audioRef.current.pause();
        };
    }, [isPlaying, audioUrl]);

    function setCardContainerSize() {
        const parentContainer = document.querySelector(".galleryContainer");
        const parentHeight = parentContainer.clientHeight;
        const cardContainers = document.querySelectorAll(".cardContainer");

        const cardSize = parentHeight / 3 - 1;

        cardContainers.forEach((card) => {
            card.style.height = `${cardSize}px`;
            card.style.width = `${cardSize}px`;
        });
    }

    useEffect(() => {
        setCardContainerSize();
        window.addEventListener("resize", setCardContainerSize);
        return () => {
            window.removeEventListener("resize", setCardContainerSize);
        };
    }, []);

    return (
        <div
            className="cardContainer"
            data-id={id}
            data-title={title}
            data-artist={artist}
            data-credits={credits}
            data-year={year}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <img src={imgUrl} alt={title} />
        </div>
    );
}

export default Card;
