import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <>
            <div className="sideBar">
                <div className="row">
                    <div className="bioText">
                        Daniel Laner <br />
                        mixing + mastering <br />
                        contact@daniellaner.com <br />
                    </div>
                    <div className="ctaButton">
                        <a href="/contact">Work with me →</a>
                    </div>
                </div>
                <div className="row">
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>✶</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </div>
                <div className="row">
                    <div>Los angeles, california</div>
                    <div id="mob-only">
                        © Daniel Laner, 2024. All Rights Reserved
                    </div>
                    <div id="des-only">© 2024</div>
                </div>
            </div>
        </>
    );
}

export default Footer;
