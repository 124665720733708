import React from "react";
import "../index.css";
import { motion } from "framer-motion";
import AnimatedCard from "../components/AnimatedCard/AnimatedCard";

function Contact() {
    function setViewportProperty() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Run on load and resize
    setViewportProperty(); // Call the function on initial load
    window.addEventListener("DOMContentLoaded", setViewportProperty);
    window.addEventListener("resize", setViewportProperty);

    return (
        <AnimatedCard>
            <div
                className="pageContainer"
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <form
                    name="contact"
                    method="post"
                    className="formContainer"
                    action="/"
                >
                    <img
                        style={{
                            width: "16px",
                            opacity: "0.2",
                            marginBottom: "0px",
                        }}
                        src="images/logo.png"
                    />
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                        className="formInput"
                        type="text"
                        name="name"
                        placeholder="NAME"
                    />
                    <input
                        className="formInput"
                        type="email"
                        name="email"
                        placeholder="EMAIL"
                    />
                    <input
                        className="formInput"
                        type="tel"
                        name="phone"
                        placeholder="PHONE #"
                    />
                    <input
                        type="text"
                        className="formInput"
                        name="details"
                        placeholder="PROJECT DETAILS"
                    ></input>
                    <button
                        className="okButton"
                        style={{
                            padding: "4px 12px",
                            marginTop: "48px",
                            cursor: "pointer",
                            width: "auto",
                            color: "#5f5e5e",
                            fontSize: "14px",
                            textTransform: "uppercase",
                            borderRadius: "8px",
                            border: "1px solid #c8c5c5",
                            border: "none",
                        }}
                        type="submit"
                    >
                        SUBMIT
                    </button>
                </form>
                <div className="backButton ctaButton">
                    <a href="/">← BACK TO SITE</a>
                </div>
            </div>
        </AnimatedCard>
    );
}

export default Contact;
