import React, { createContext, useContext, useState } from "react";

const UserActivationContext = createContext();
export const useUserActivation = () => useContext(UserActivationContext);

export const UserActivationProvider = ({ children }) => {
    const [isActivated, setIsActivated] = useState(false);

    return (
        <UserActivationContext.Provider value={{ isActivated, setIsActivated }}>
            {children}
        </UserActivationContext.Provider>
    );
};
