import React, { useState, useEffect } from "react";
import Loading from "./components/LoadingScreen/LoadingScreen";
import Contact from "./pages/Contact";
import Home from "./pages/Home";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

function App() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoading(false), 2000);
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
        </Routes>
    );
}

export default App;
