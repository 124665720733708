import React from "react";
import "./LoadingScreen.css";

function LoadingScreen() {
    return (
        <>
            <div className="loadContainer">
                <div className="logoContainer">
                    <img src="images/logo.png" />
                </div>
            </div>
        </>
    );
}

export default LoadingScreen;
