import React, { useState, useEffect } from "react";

function AnimatedCard({ children }) {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    return (
        <div style={{ opacity: isMounted ? 1 : 0, transition: "opacity 0.5s" }}>
            {children}
        </div>
    );
}

export default AnimatedCard;
